const SERVICE_AD_STORAGE = 'ad_storage';
const SERVICE_AD_USER_DATA = 'ad_user_data';
const SERVICE_AD_PERSONALIZATION = 'ad_personalization';
const SERVICE_ANALYTICS_STORAGE = 'analytics_storage';
const SERVICE_FUNCTIONALITY_STORAGE = 'functionality_storage';
const SERVICE_PERSONALIZATION_STORAGE = 'personalization_storage';
const SERVICE_SECURITY_STORAGE = 'security_storage';

// Define dataLayer and the gtag function.
window.dataLayer = window.dataLayer || [];

// eslint-disable-next-line no-undef, prefer-rest-params
function gtag() { dataLayer.push(arguments); }

// Set default consent to 'denied' (this should happen before changing any other dataLayer)
gtag('consent', 'default', {
  [SERVICE_AD_STORAGE]: 'granted',
  [SERVICE_AD_USER_DATA]: 'granted',
  [SERVICE_AD_PERSONALIZATION]: 'granted',
  [SERVICE_ANALYTICS_STORAGE]: 'granted',
  [SERVICE_FUNCTIONALITY_STORAGE]: 'granted',
  [SERVICE_PERSONALIZATION_STORAGE]: 'granted',
  [SERVICE_SECURITY_STORAGE]: 'granted',
});
